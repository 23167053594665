import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import autoBind from "react-autobind";
import { translations } from "../../sharedJs/translations";
import FullMenuList from "./FullMenuList";
import LanguageMenu from "../navMenu/LanguageMenu";

import "../../styles/_utilities.scss";
import "./_navMenu.scss";

// import { bulkSetAttribute } from '../../sharedJs/utilityFunctions';

class NavMenu extends Component {
  constructor(props) {
    super(props);
    autoBind(this);
    this.navMenuRef = React.createRef();
    this.state = {
      navClassName: "c_master-nav"
    };
  }

  componentDidMount() {
    document.body.addEventListener("click", this.closeOnClickOutside);
  }

  componentWillUnmount() {
    document.body.removeEventListener("click", this.closeOnClickOutside);
  }

  closeOnClickOutside(event) {
    // contains = function that checks if selected element is child of certain parent element
    const contains = (parent, child) =>
      parent !== child && parent.contains(child);
    const parentElement = document.querySelector(".c_master-nav");
    const clickedElement = event.target;
    // Check if menu is open and clicked element is not inside master-nav
    // If OK, close menu and prevent other click events below from firing
    if (this.props.isOpen && !contains(parentElement, clickedElement)) {
      this.props.closeMenu();
      event.stopPropagation();
      event.preventDefault();
    }
  }

  render() {
    const translate = translations(this.props.language.name);
    return (
      <div
        id="masterNavMenu"
        className={`${this.state.navClassName}${
          this.props.isOpen ? " c_master-nav--open" : ""
        }`}
        ref={this.navMenuRef}
      >
        {this.props.epiDataModelLoaded && this.props.epiDataModel.header.menu && (
          <Fragment>
            <FullMenuList
              closeMenu={this.props.closeMenu}
              focusHandler={this.props.focusHandler}
              isOpen={this.props.isOpen}
              translate={translate}
              language={this.props.language}
              menuItems={this.props.menuItems}
              topMenuItems={this.props.topMenuItems}
            />
          </Fragment>
        )}
        {this.props.epiDataModelLoaded &&
          this.props.epiDataModel.header.menu.languageMenu && (
            <Fragment>
              <LanguageMenu
                className="u_hidden--desktop"
                menuItems={this.props.epiDataModel.header.menu.languageMenu}
              />
            </Fragment>
          )}
      </div>
    );
  }
}

NavMenu.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  closeMenu: PropTypes.func.isRequired,
  location: PropTypes.object,
  focusHandler: PropTypes.func,
  language: PropTypes.object,
  epiDataModel: PropTypes.object,
  epiDataModelLoaded: PropTypes.bool
};

const mapStateToProps = state => ({
  language: state.app.language,
  epiDataModel: state.app.epiDataModel,
  epiDataModelLoaded: state.app.epiDataModelLoaded
});

export default connect(mapStateToProps, {})(NavMenu);
