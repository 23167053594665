const StartPage = () => import(/* webpackChunkName: "StartPage" */ './templates/startPage/StartPage');
const ArticlePage = () => import(/* webpackChunkName: "ArticlePage" */ './templates/ArticlePage/ArticlePage');
const LandingPage = () => import(/* webpackChunkName: "ArticlePage" */ './templates/LandingPage/LandingPage');
const ListPage = () => import(/* webpackChunkName: "ArticlePage" */ './templates/ListPage/ListPage');
const ArticleImageBlock = () => import(/* webpackChunkName: "ArticlePage" */ './components/nationalParksComponents/articleImageBlock/ArticleImageBlock');
const ErrorPage = () => import(/* webpackChunkName: "ArticlePage" */ './templates/errorPage/ErrorPage');

export const EPI_COMPONENTS = [
    {
        id: 'NationalParksStartPage',
        componentPromise: StartPage
    },
    {
        id: 'NationalParksArticlePage',
        componentPromise: ArticlePage
    },
    {
        id: 'NationalParksLandingPage',
        componentPromise: LandingPage
    },
    {
        id: 'NationalParksListPage',
        componentPromise: ListPage
    },
    {
        id: 'ArticleImageBlock',
        componentPromise: ArticleImageBlock
    },
    {
        id: 'NationalParksErrorPage',
        componentPromise: ErrorPage
    }
];

export default EPI_COMPONENTS;
