import React from 'react';
import SvgElement from '../svgElement/SvgElement';

import '../../../styles/_utilities.scss';
import './_loadingSpinner.scss';

const loadingIcon = '/assets/icons/icon-loading.svg';

const LoadingSpinner = () => (
    <div className="c_loading-spinner">
        <SvgElement
            src={loadingIcon}
            title="Laster-ikon"
            desc="Laster..."
        />
        <span className="u_visuallyhidden">Laster. Vennligst vent</span>
    </div>
);

export default LoadingSpinner;
