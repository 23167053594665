import React, { Component } from 'react';
import PropTypes from 'prop-types';
import autoBind from 'react-autobind';
import { connect } from 'react-redux';
import EpiLink from '../links/epiLink/EpiLink';

import '../../styles/_utilities.scss';
import '../../styles/_grid.scss';
import './_languageMenu.scss';

class ChooseLanguage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            chosenLanguage: 'norwegian'
        };
        autoBind(this);
    }


    // Bytte språk. TODO
    changeLanguage() {
        //console.log('Bytter språk');
        this.setState({
            chosenLanguage: 'nyttSpraak'
        });
    }

    render() {
        return (

            <div className={`c_choose-language ${this.props.className}`}>
                <ul>
                    {this.props.menuItems.map((item, index) => {
                        return (
                            <li key={`language-menu-item___${index}`}>
                                <EpiLink
                                    url={item.url}
                                >
                                    {item.pageName}
                                </EpiLink>
                            </li>
                        );
                    })}
                    {/* <li>
                        <button className="c_text-button" type="button" >
                            {translate.App.LanguageButtonNo}
                        </button>
                    </li>
                    <li>
                        <button className="c_text-button" type="button" >
                            {translate.App.LanguageButtonEn}
                        </button>
                    </li> */}
                    {/* <li>
                        <button className="c_text-button" type="button" >
                            {translate.App.LanguageButtonDe}
                        </button>
                    </li>
                    <li>
                        <button className="c_text-button" type="button" >
                            {translate.App.LanguageButtonSami}
                        </button>
                    </li> */}
                </ul>
            </div>
        );
    }
}

ChooseLanguage.propTypes = {
    className: PropTypes.string,
    location: PropTypes.object,
    language: PropTypes.object,
    epiDataModel: PropTypes.object,
    epiDataModelLoaded: PropTypes.bool
};

const mapStateToProps = state => ({
    language: state.app.language,
    epiDataModel: state.app.epiDataModel,
    epiDataModelLoaded: state.app.epiDataModelLoaded
});

export default connect(mapStateToProps, {})(ChooseLanguage);
