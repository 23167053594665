let serverSide = false;

if (typeof window === 'undefined') {
    serverSide = true;
}

export const stripStringForHtml = (usersString) => {
    const doc = new DOMParser().parseFromString(usersString, 'text/html');
    return doc.body.textContent || '';
};

export const isKeyPressed = (event, key) => {
    if (event.defaultPrevented) {
        return false; // Should do nothing if the default action has been cancelled
    }

    let handled = false;
    if (event.key !== undefined && event.key === key) {
        // Handle the event with KeyboardEvent.key and set handled true.
        handled = true;
    } else if (event.keyIdentifier !== undefined && event.keyIdentifier === key) {
        // Handle the event with KeyboardEvent.keyIdentifier and set handled true.
        handled = true;
    } else if (event.keyCode !== undefined && event.keyCode === key) {
        // Handle the event with KeyboardEvent.keyCode and set handled true.
        handled = true;
    }

    if (handled) {
        // Suppress "double action" if event handled
        event.preventDefault();
    }

    return handled;
};

const base = {
    params: ('URLSearchParams' in window) ? new URLSearchParams(window.location.search) : ['', '']
};

/**
 * @desc url object for manipulating url
 * @param searchParams - getters and setters for url search params
 */
export const url = {
    searchParams: {
        get: ('URLSearchParams' in window) ?
            param => base.params.get(param)
            : null,
        set: ('URLSearchParams' in window) ?
            (param, value) => base.params.set(param, value)
            : null,
        update: ('URLSearchParams' in window) ?
            () => window.history.pushState({}, '', `#/klimagass${window.location.pathname}?${base.params}`)
            : null,
        delete: ('URLSearchParams' in window) ?
            param => base.params.delete(param)
            : null,
        setAndUpdate: ('URLSearchParams' in window) ?
            (param, value) => {
                base.params.set(param, value);
                return window.history.pushState({}, '', `#/klimagass${window.location.pathname}?${base.params}`);
            } : null,
        deleteAndUpdate: ('URLSearchParams' in window) ?
            (param) => {
                base.params.delete(param);
                return window.history.pushState({}, '', `#/klimagass${window.location.pathname}?${base.params}`);
            } : null
    }
};

export const numberFormat = (props) => {
    const {
        num,
        format = 'no-NO'
    } = props;
    if (num === null) {
        return '';
    }
    try {
        if ('Intl' in window && 'NumberFormat' in window.Intl) {
            return new Intl.NumberFormat(format, { maximumSignificantDigits: 3 }).format(num);
        }
        if (num % 1) {
            return Number.parseFloat(num).toFixed(2).replace('.', ',');
        }
        return num;
    } catch (e) {
        return num;
    }
};

export const b64 = {
    encode: ('btoa' in window) ? param => btoa(param) : param => param,
    decode: ('atob' in window) ? param => atob(param) : param => param
};

export const isNumeric = value => Number(parseFloat(value)) === value;

export const subscriptMapper = (text, renderHtmlTag) => {
    if (!text || text === '' || text === null || isNumeric(text)) {
        return text;
    }

    const arr = [
        {
            text: ['ug/m3', 'µg/m³'],
            subscript: renderHtmlTag === true ? 'µg/m<sup>3</sup>' : 'µg/m³'
        },
        {
            text: ['CO2', 'CO₂'],
            subscript: renderHtmlTag === true ? 'CO<sub>2</sub>' : 'CO₂'
        },
        {
            text: ['CH4', 'CH₄'],
            subscript: renderHtmlTag === true ? 'CH<sub>4</sub>' : 'CH₄'
        },
        {
            text: ['N2O', 'N₂O'],
            subscript: renderHtmlTag === true ? 'N<sub>2</sub>O' : 'N₂O'
        },
        {
            text: ['NOX', 'NOₓ'],
            subscript: renderHtmlTag === true ? 'NO<sub>x</sub>' : 'NOₓ'
        },
        {
            text: ['KM2', 'km²'],
            subscript: renderHtmlTag === true ? 'km<sup>2</sup>' : 'km²'
        },
        {
            text: ['M3', 'm³'],
            subscript: renderHtmlTag === true ? 'm<sup>3</sup>' : 'm³'
        },
        {
            text: ['NO2', 'NO₂'],
            subscript: renderHtmlTag === true ? 'NO<sub>2</sub>' : 'NO₂'
        },
        {
            text: ['PM10', 'PM₁₀'],
            subscript: renderHtmlTag === true ? 'PM<sub>10</sub>' : 'PM₁₀'
        },
        {
            text: ['PM25', 'PM2.5', 'PM2,5', 'PM₂.₅'],
            subscript: renderHtmlTag === true ? 'PM<sub>2.5</sub>' : 'PM₂.₅'
        },
        {
            text: ['SO2', 'SO₂'],
            subscript: renderHtmlTag === true ? 'SO<sub>2</sub>' : 'SO₂'
        },
        {
            text: ['O3', 'O₃'],
            subscript: renderHtmlTag === true ? 'O<sub>3</sub>' : 'O₃'
        }
    ];
    let out = text.toString();
    for (let i = 0; i < arr.length; i++) {
        for (let x = 0; x < arr[i].text.length; x++) {
            try {
                // not exactly water proof but better than nothing:
                // find the text but not preceding "> i.e. not inside an attribute - e.g. href="lalalalam3" >
                out = out.replace(new RegExp(`${arr[i].text[x]}(?!(.*)\\"\\>)\\b`, 'gi'), arr[i].subscript);
            } catch (ex) {
                // console.log(ex);
            }
        }
    }
    return out;
};

const isIE = serverSide || (false || !!document.documentMode);
const isChrome = serverSide || (!!window.chrome && !!window.chrome.webstore);
const isOpera = serverSide || ((!!window.opr && !!window.opr.addons) || !!window.opera || navigator.userAgent.indexOf(' OPR/') >= 0);

export const browserDetection = {
    isOpera,
    // Firefox 1.0+
    isFirefox: serverSide || typeof InstallTrigger !== 'undefined',
    // Safari 3.0+ "[object HTMLElementConstructor]"
    isSafari: serverSide || (/constructor/i.test(window.HTMLElement)
        || (p => p.toString() === '[object SafariRemoteNotification]')(!window.safari
            || (typeof safari !== 'undefined' && window.safari.pushNotification))),
    // Internet Explorer 6-11 /*@cc_on!@*/
    isIE,
    // Edge 20+
    isEdge: serverSide || (!isIE && !!window.StyleMedia),
    isChrome,
    // Blink engine detection
    isBlink: serverSide || ((isChrome || isOpera) && !!window.CSS)
};

export const deepClone = arr => JSON.parse(JSON.stringify(arr));

const getUrlVars = (url) => {
    const vars = {};
    url.replace(/[?&]+([^=&]+)=([^&]*)/gi, (m, key, value) => {
        vars[key] = value;
    });
    return vars;
};
export const getUrlParameter = (url, parameter, defaultvalue) => {
    let urlparameter = defaultvalue;
    if (url.indexOf(parameter) > -1) {
        urlparameter = getUrlVars(url)[parameter];
    }
    return urlparameter;
};
export const getUrlParam = (parameter, defaultvalue) => getUrlParameter(window.location.href, parameter, defaultvalue);

export const updateUrlParam = (url, param, paramVal) => {
    let newAdditionalURL = '';
    let tempArray = url.split('?');
    const baseURL = tempArray[0];
    const additionalURL = tempArray[1];
    let temp = '';
    if (additionalURL) {
        tempArray = additionalURL.split('&');
        for (let i = 0; i < tempArray.length; i++) {
            if (tempArray[i].split('=')[0] !== param) {
                newAdditionalURL += temp + tempArray[i];
                temp = '&';
            }
        }
    }
    const rowsTxt = `${temp}${param}=${paramVal}`;
    return `${baseURL}?${newAdditionalURL}${rowsTxt}`;
};

export const bulkSetAttribute = (props) => {
    const {
        items,
        attr,
        value
    } = props;
    return items.map(item => item.setAttribute(attr, value));
};

export const copyToClipboard = (str) => {
    const el = document.createElement('textarea');
    el.value = str;
    document.body.appendChild(el);
    el.select();
    document.execCommand('copy');
    document.body.removeChild(el);
};

export const chartDataToTable = (props) => {
    const {
        data,
        label,
        chartType
    } = props;
    const chartData = data;
    const tableData = {
        tableHeading: label,
        hideTableHeading: true,
        columns: []
    };

    const leftColObj = {
        heading: '',
        values: chartData.map(trace => trace.name)
    };

    tableData.columns.push(leftColObj);

    if (chartType === 'HorizontalBarChart') {
        const rows = [];
        for (let i = 0; i < chartData.length; i++) {
            for (let currentHeading = 0; currentHeading < chartData[i].y.length; currentHeading++) {
                const heading = chartData[i].y[currentHeading];

                if (!rows.find(h => h.heading === heading)) {
                    rows.push({
                        heading,
                        values: []
                    });
                }

                rows.find(h => h.heading === heading).values.push(chartData[i].x[currentHeading]);
            }
        }
        tableData.columns = tableData.columns.concat(rows);
    } else {
        let longest = chartData[0].x.length;
        let longestSeries = 0;
        for (let index = 0; index < chartData.length; index++) {
            if (chartData[index].x.length > longest) {
                longest = chartData[index].x.length;
                longestSeries = index;
            }
        }

        for (let i = 0; i < chartData[longestSeries].x.length; i++) {
            const yearObj = {
                heading: chartData[longestSeries].x[i],
                values: chartData.map(trace => trace.y[i])
            };
            tableData.columns.push(yearObj);
        }
        return tableData;
    }
    return tableData;
};

/**
 * @desc maps graph data for populating graph components
 * @param array $array - the array to be transformed
 * @return array - { name, x, y }
 */
export const tableDataToChart = (props) => {
    const {
        type,
        data
    } = props;

    switch (type) {
    case 'HorizontalBarChart': {
        const labels = data[0].values.map(label => label);
        const rest = labels.map((label, index) => ({
            heading: label,
            values: data.slice(1).map(item => item.values[index])
        }));
        const preparedData = [{
            heading: 'Utslippkilde',
            values: data.slice(1).map(item => item.heading)
        }, ...rest];

        const source = preparedData; // data

        const headings = deepClone(source[0].values);
        const out = headings.map((col, index) => ({
            name: subscriptMapper(headings[index]),
            y: source.slice(1).map(title => subscriptMapper(title.heading)),
            x: source.slice(1).map(item => subscriptMapper(item.values[index]))
        }));
        return out;
    }
    case 'DonutChart': {
        const headings = deepClone(data[0].values);
        const out = headings.map(() => ({
            x: headings.map(item => subscriptMapper(item)),
            y: deepClone(data[1].values)
        }));
        return out.shift();
    }
    default: {
        // Applys to 'barChart', 'lineChart', 'barChartWithLine'
        const headings = deepClone(data[0].values);
        const out = headings.map((col, index) => ({
            name: subscriptMapper(headings[index]),
            x: data.slice(1).map(title => subscriptMapper(title.heading)),
            y: data.slice(1).map(item => subscriptMapper(item.values[index]))
        }));
        return out;
    }
    }
};

export const isTouchDevice = () => {
    const prefixes = ' -webkit- -moz- -o- -ms- '.split(' ');
    const mq = query => window.matchMedia(query).matches;

    if (('ontouchstart' in window) || (window.DocumentTouch && document instanceof DocumentTouch)) { // eslint-disable-line
        return true;
    }

    // include the 'heartz' as a way to have a non matching MQ to help terminate the join
    // https://git.io/vznFH
    const query = ['(', prefixes.join('touch-enabled),('), 'heartz', ')'].join('');
    return mq(query);
};

export const hexToRGB = (hex, alpha) => {
    const r = parseInt(hex.slice(1, 3), 16);
    const g = parseInt(hex.slice(3, 5), 16);
    const b = parseInt(hex.slice(5, 7), 16);

    if (alpha) {
        return `rgba(${r},${g},${b},${alpha})`;
    }

    return `rgba(${r},${g},${b})`;
};

export const isMobile = () => {
    return (
        /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|ipad|iris|kindle|Android|Silk|lge |maemo|midp|mmp|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows (ce|phone)|xda|xiino/i.test(
            navigator.userAgent
        ) ||
        /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw-(n|u)|c55\/|capi|ccwa|cdm-|cell|chtm|cldc|cmd-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc-s|devi|dica|dmob|do(c|p)o|ds(12|-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(-|_)|g1 u|g560|gene|gf-5|g-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd-(m|p|t)|hei-|hi(pt|ta)|hp( i|ip)|hs-c|ht(c(-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i-(20|go|ma)|i230|iac( |-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|-[a-w])|libw|lynx|m1-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|-([1-8]|c))|phil|pire|pl(ay|uc)|pn-2|po(ck|rt|se)|prox|psio|pt-g|qa-a|qc(07|12|21|32|60|-[2-7]|i-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h-|oo|p-)|sdk\/|se(c(-|0|1)|47|mc|nd|ri)|sgh-|shar|sie(-|m)|sk-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h-|v-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl-|tdg-|tel(i|m)|tim-|t-mo|to(pl|sh)|ts(70|m-|m3|m5)|tx-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas-|your|zeto|zte-/i.test(
            navigator.userAgent.substr(0, 4)
        ) || window.innerWidth < 769
    );
}

export const truncate = (input, length = 100) =>  {
    if (input.length > length)
       return input.substring(0, length) + '...';
    else
       return input;
 };

export const isIOSDevice = () => {
    return !!navigator.platform && /iPad|iPhone|iPod/.test(navigator.platform);
}
