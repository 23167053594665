export const FEEDBACKBAR_TYPE_POSITIVE = 'FEEDBACKBAR_TYPE_POSITIVE';
export const FEEDBACKBAR_TYPE_NEGATIVE = 'FEEDBACKBAR_TYPE_NEGATIVE';
export const FEEDBACKBAR_RESET = 'FEEDBACKBAR_RESET';
export const FEEDBACKBAR_SET_ISLOADING = 'FEEDBACKBAR_SET_ISLOADING';
export const FEEDBACKBAR_SET_ISOPEN = 'FEEDBACKBAR_SET_ISOPEN';
export const FEEDBACKBAR_SEND_FEEDBACK = 'FEEDBACKBAR_SEND_FEEDBACK';
export const FEEDBACKBAR_POST_FEEDBACK_MESSAGE = 'FEEDBACKBAR_POST_FEEDBACK_MESSAGE';

export const LOAD_EPIDATAMODEL = 'LOAD_EPIDATAMODEL';
export const UPDATE_CONTEXT = 'UPDATE_CONTEXT';
export const UPDATE_MODEL_BY_CONTENT_LINK = 'UPDATE_MODEL_BY_CONTENT_LINK';
export const SET_EPIMODEL_ISLOADING = 'SET_EPIMODEL_ISLOADING';

export const GLOSSARY_SET_ISOPEN = 'GLOSSARY_SET_ISOPEN';
