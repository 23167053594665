import React, { Component } from 'react';
import PropTypes from 'prop-types';
import autoBind from 'react-autobind';
import { connect } from 'react-redux';
import { translations } from '../../../sharedJs/translations';
import EpiLink from '../../links/epiLink/EpiLink';
import NavMenu from '../../navMenu/NavMenu';
import TopMenu from '../../navMenu/TopMenu';
import SvgElement from '../../uiElements/svgElement/SvgElement';
import LanguageMenu from '../../navMenu/LanguageMenu';
import { isMobile } from '../../../sharedJs/utilityFunctions';
import Headroom from 'react-headroom';

import '../../../styles/_utilities.scss';
import '../../../styles/_grid.scss';
import './_header.scss';

const nationalparkLogo = '/assets/nationalparks/logo/logo_nationalpark_black.svg';

class Header extends Component {
    constructor(props) {
        super(props);
        this.state = {
            navMenuIsOpen: false,
            siteTitle: ''
        };
        autoBind(this);
    }

    componentDidMount() {
        this.updateMessage(this.props.language.name);
    }

    componentDidUpdate(prevProps) {
        if ((this.props.location && prevProps.location) && (this.props.location.pathname !== prevProps.location.pathname)) {
            this.setState({
                navMenuIsOpen: false
            }); // eslint-disable-line
        }

        this.updateMessage(this.props.language.name);
    }

    //header does not initialy mount with correct language
    updateMessage(languageName) {
        var newMessage = translations(languageName).ParkPage.TitleWithTags;
        if (!this.state.message || newMessage !== this.state.message.__html)
            this.setState({ message: { __html: newMessage} });
    }

    getHeaderClassName() {
        let cN = '';
        if (this.props.location && this.props.location.pathname === '/') {
            cN = `${this.props.className}--nationalpark-frontpage`;
        }
        return `${this.props.className} ${this.props.className}--nationalpark ${cN}`;
    }

    getLogo(isDesktop) {
        return this.props.language.name === 'en' ? nationalparkLogo : nationalparkLogo;
    }

    focusHandler() {
        if (!this.state.navMenuIsOpen) {
            this.setState({ navMenuIsOpen: true });
        }
    }

    toggleNavMenu() {
        this.setState({
            navMenuIsOpen: !this.state.navMenuIsOpen
        });

    }

    renderDangerouslyHtml(text) {
        this.setState({
            message: { __html: text },
        });
    }

    renderHeader() {
        const { pathname } = this.props.location;
        
        const translate = translations(this.props.language.name);

        return (
            <header
                id="header"
                className={`${this.getHeaderClassName()} ${pathname === '/' ? `${this.props.className}--front-page` : ''}`}
            >
                <a
                    href="#content"
                    className="skipLink skipLink--top"
                >{translate.App.SkipToMainContent}
                </a>
                <div className="g_container g_container--full" id="headerLogo">
                    <div className={`${this.props.className}__inner`}>

                        {this.props.epiDataModelLoaded && this.props.epiDataModel.header.startPageUrl &&

                            <EpiLink
                                url={this.props.epiDataModel.header.startPageUrl}
                                className={`${this.props.className}__home-link`}
                                aria-label={translate.App.BackToFrontPage}
                            >
                                <span className="u_visuallyhidden">{translate.App.BackToFrontPage}</span>
                                <SvgElement
                                    src={this.getLogo(true)}
                                    title={translate.App.BackToFrontPage}
                                    desc={translate.App.GoBackToFrontPage}
                                    className={`${this.props.className}__logo ${this.props.className}`}
                                />
                                {translate.App.SiteTitle && (
                                    <span
                                        className={`${this.props.className}__site-title`}
                                        dangerouslySetInnerHTML={this.state.message}
                                    />
                                )}

                            </EpiLink>
                        }
                        <div className={`${this.props.className}__actions-wrapper`}>
                            {this.props.epiDataModelLoaded && this.props.epiDataModel.header.menu.languageMenu && (
                                <LanguageMenu
                                    className="u_hidden--mobile"
                                    menuItems={this.props.epiDataModel.header.menu.languageMenu}
                                />
                            )}
                            <button
                                onClick={() => this.toggleNavMenu()}
                                className="c_nav-toggle-button"
                                aria-label={translate.App.Menu}
                                aria-expanded={this.state.navMenuIsOpen}
                                aria-controls="masterNavMenu"
                            >
                                <span className={`c_nav-toggle-button__icon ${this.state.navMenuIsOpen ? 'c_nav-toggle-button__icon--close-menu' : ''}`}>
                                    <span></span>
                                    <span></span>
                                    <span></span>
                                    <span></span>
                                </span>
                                {translate.App.Menu}
                            </button>
                        </div>
                    </div>
                </div>
                {this.props.epiDataModelLoaded && this.props.epiDataModel.header.menu.fullMenu && (
                    <NavMenu
                        isOpen={this.state.navMenuIsOpen}
                        closeMenu={this.toggleNavMenu}
                        location={this.props.location}
                        focusHandler={this.focusHandler}
                        menuItems={this.props.epiDataModel.header.menu.fullMenu}
                        topMenuItems={isMobile() ? this.props.epiDataModel.header.menu.topMenu : null}
                    />
                )}
                {this.props.epiDataModelLoaded && this.props.epiDataModel.header.menu.topMenu && !isMobile() && ( // hide TopMenu on mobile
                    <TopMenu
                        location={this.props.location}
                        menuItems={this.props.epiDataModel.header.menu.topMenu}
                    />

                )}
            </header>
        );
    }

    render() {
        if (isMobile()) {
            return (
                <Headroom
                    style={{
                        zIndex: 9999
                    }}
                >
                    {this.renderHeader()}
                </Headroom>
            );
        }

        return (
            this.renderHeader()
        );
    }
}

Header.propTypes = {
    className: PropTypes.string.isRequired,
    location: PropTypes.object,
    language: PropTypes.object,
    epiDataModel: PropTypes.object,
    epiDataModelLoaded: PropTypes.bool,
    searchIsLoading: PropTypes.bool
};

const mapStateToProps = state => ({
    language: state.app.language,
    epiDataModel: state.app.epiDataModel,
    epiDataModelLoaded: state.app.epiDataModelLoaded
});

export default connect(mapStateToProps, {})(Header);
