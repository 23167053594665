import React from 'react';
import PropTypes from 'prop-types';
import FullMenuItem from './FullMenuItem';

const FullMenuList = props => (
    <div className="c_nav-section-list">
        {props.topMenuItems &&
            <ul className="c_nav-list c_nav-list--top-level__topmenu-items">
                {props.topMenuItems.map((item) => {
                    return (
                        <FullMenuItem
                            key={`${item.id}_${item.url}`}
                            data={item}
                            changeLevelToShow={props.changeLevelToShow}
                            active={item.isActivePage}
                            focusHandler={props.focusHandler}
                            language={props.language}
                        />
                    );
                })}
            </ul>
        }
        <ul className="c_nav-list c_nav-list--top-level">
            {props.menuItems.map((levelItem) => {
                return (
                    <FullMenuItem
                        key={`${levelItem.id}_${levelItem.url}`}
                        data={levelItem}
                        changeLevelToShow={props.changeLevelToShow}
                        active={levelItem.isActivePage}
                        focusHandler={props.focusHandler}
                        language={props.language}
                    />
                );
            })}
        </ul>
    </div>
);

FullMenuList.propTypes = {
    closeMenu: PropTypes.func,
    focusHandler: PropTypes.func,
    changeLevelToShow: PropTypes.func,
    isOpen: PropTypes.bool,
    translate: PropTypes.object,
    language: PropTypes.object,
    menuItems: PropTypes.array
};

export default FullMenuList;
