import noTranslations from '../assets/translations/no.json';
import enTranslations from '../assets/translations/en.json';
import smjTranslations from '../assets/translations/smj-NO.json';

export const translations = (lang) => {
    switch (lang.toLowerCase()) {
    case 'no':
        return noTranslations;
    case 'en':
        return enTranslations;
    case 'smj-no':
        return smjTranslations;
    default:
        return noTranslations;
    }
};

export default translations;
