// Dette er den hvite menyen på toppen av siden

import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import autoBind from "react-autobind";
import { translations } from "../../sharedJs/translations";
import EpiLink from "../links/epiLink/EpiLink";
import "../../styles/_utilities.scss";
import "./_topMenu.scss";
import SvgElement from "../uiElements/svgElement/SvgElement";

const downArrow = "/assets/nationalparks/icons/icon-arrow-down-black.svg";


class TopMenu extends Component {
    constructor(props) {
        super(props);
        autoBind(this);
        this.state = {
            subMenuIsOpenMobile: "closed"
        };
    }

    componentDidMount() {
        window.addEventListener('scroll', this.handleScroll, true);
    }
    componentWillUnmount() {
        window.removeEventListener('scroll', this.handleScroll);
    }

    handleScroll() {
        const nav = document.getElementById('topMenuNav');
        const filler = document.getElementById('sticky_filler');

        if (window.pageYOffset > filler.offsetTop) {
            nav.classList.add("sticky");
            filler.style.paddingTop = nav.offsetHeight + "px";
        }
        else {
            nav.classList.remove("sticky");
            filler.style.paddingTop = "0";
        }
    }

    toggleSubMenu() {
        // window.scrollTo(0, 0);
        let css =
            this.state.subMenuIsOpenMobile === "closed" ? "open" : "closed";
        this.setState({
            subMenuIsOpenMobile: css
        });
    }
    getActiveElement() {
        const menuItems = [...this.props.menuItems];
        const activeElement = menuItems.filter(i => i.isActivePage === true);
        const articleName = this.props.epiDataModel.name
            ? this.props.epiDataModel.name
            : null;

        return activeElement[0] ? activeElement[0].pageName : articleName;
    }

    render() {
        const translate = translations(this.props.language.name);
        return (
            <Fragment>
                <div id="sticky_filler"></div>
                <div className="c_top-menu" role="navigation" id="topMenuNav">
                    <div className="g_container g_container--full">
                        <button
                            onClick={() => this.toggleSubMenu()}
                            className="c_sub-menu-toggle-button u_hidden--desktop"
                            aria-label={translate.App.Menu}
                            aria-expanded={this.state.navMenuIsOpen}
                            aria-controls="subNavMenu"
                        >
                            {this.getActiveElement()}
                            <SvgElement
                                src={downArrow}
                                title="Ikon i knapp"
                                desc="Ikon"
                                className={
                                    "arrow-down__icon " +
                                    this.state.subMenuIsOpenMobile
                                }
                            />
                        </button>
                        <ul
                            className={
                                "c_top-menu-list " + this.state.subMenuIsOpenMobile
                            }
                        >
                            {this.props.menuItems.map((item, index) => (
                                <li
                                    onClick={() => this.toggleSubMenu()}
                                    key={`top-menu-item-${index}`}
                                    className={
                                        item.isActivePage ? "active-page" : ""
                                    }
                                >
                                    <EpiLink url={item.url} title={`${translate.App.GoTo} ${item.pageName}`}>
                                        {item.pageName}
                                    </EpiLink>
                                </li>
                            ))}
                        </ul>
                    </div>
                </div>
            </Fragment>
        );
    }
}

TopMenu.propTypes = {
    language: PropTypes.object,
    epiDataModel: PropTypes.object,
    epiDataModelLoaded: PropTypes.bool
};

const mapStateToProps = state => ({
    language: state.app.language,
    epiDataModel: state.app.epiDataModel,
    epiDataModelLoaded: state.app.epiDataModelLoaded
});

export default connect(mapStateToProps, {})(TopMenu);
