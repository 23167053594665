import React from 'react';

let SvgElement = props => (
    <img
        src={props.src}
        aria-hidden="true"
        className={props.className}
        title={props.title}
        alt={props.desc}
    />

);

SvgElement = React.memo(SvgElement);

export default SvgElement;
