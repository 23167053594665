import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import getComponentTypeForContent from './api/getComponentTypeForContent';
import { EPI_COMPONENTS } from './epiComponents';
import { updateContext, updateModelByUrl, setEpiModelLoading } from './actions/index';
import { showGlossary } from './actions/glossaryActions';
import LoadingSpinner from './components/uiElements/loadingSpinner/LoadingSpinner';
import store from './store';

class PageComponentSelector extends PureComponent {
    constructor(props) {
        super(props);
        if (props.epiDataModelLoaded) {
            const epiComponent = getComponentTypeForContent(props.epiDataModel, EPI_COMPONENTS);
            if (epiComponent && epiComponent.componentPromise) {
                epiComponent.componentPromise().then(({ default: Component }) => {
                    this.state({
                        component: Component
                    });
                    document.title = props.epiDataModel.name;
                    store.dispatch(setEpiModelLoading(false));
                });
            }
        } else {
            this.state = { };
        }
    }

    componentDidUpdate(prevProps) {
        if (this.props.epiDataModel !== prevProps.epiDataModel) {
            if (this.props.epiDataModelLoaded) {
                const epiComponent = getComponentTypeForContent(this.props.epiDataModel, EPI_COMPONENTS);
                this.getComponent(epiComponent);
            }
        }
    }

    getComponent(epiComponent) {
        if (epiComponent && epiComponent.componentPromise) {
            epiComponent.componentPromise().then(({ default: Component }) => {
                this.setState({
                    component: Component
                });
                document.title = this.props.epiDataModel.name;
                store.dispatch(setEpiModelLoading(false));
            });
        }
    }


    render() {
        if (this.props.isLoading || !this.props.epiDataModelLoaded) {
            return (
                <main id="content" tabIndex="-1" style={{ outline: 'none' }} className="c_loading-main">
                    <LoadingSpinner />
                </main>
            );
        }

        if (this.props.epiDataModelLoaded) {
            if (this.state.component) {
                const Component = this.state.component;
                return (
                    <main id="content" tabIndex="-1" style={{ outline: 'none' }} className="c_np-main">
                        <Component
                            model={this.props.epiDataModel}
                            {...this.props}
                        />
                    </main>
                );
            }

            return <LoadingSpinner />;
        }

        return (
            <main id="content" tabIndex="-1" style={{ outline: 'none' }}>
            </main>
        );
    }
}

PageComponentSelector.propTypes = {
    epiDataModel: PropTypes.any,
    epiDataModelLoaded: PropTypes.bool,
    glossaryIsOpen: PropTypes.bool,
    glossaryText: PropTypes.string,
    glossaryOffset: PropTypes.shape({
        top: PropTypes.number,
        left: PropTypes.string,
        right: PropTypes.string
    }),
    glossaryArrowLeft: PropTypes.bool,
    showGlossary: PropTypes.func,
    isLoading: PropTypes.bool
};

const mapStateToProps = state => ({
    epiDataModel: state.app.epiDataModel,
    epiDataModelLoaded: state.app.epiDataModelLoaded,
    language: state.app.language,
    isLoading: state.app.isLoading,
    glossaryIsOpen: state.glossary.isOpen,
    glossaryText: state.glossary.text,
    glossaryOffset: state.glossary.offset,
    glossaryArrowLeft: state.glossary.arrowLeft
});

export default connect(mapStateToProps, {
    updateContext,
    updateModelByUrl,
    showGlossary
})(PageComponentSelector);
