import React from 'react';
import PropTypes from 'prop-types';
import EpiLink from '../links/epiLink/EpiLink';

const FullMenuItem = (props) => {

    return (
        <li className="c_nav-list__item">
            <EpiLink className="c_nav-list__item-content" url={props.data.url} onFocus={() => props.focusHandler()}>
                <span className={props.active ? 'u_font-weight-medium' : ''}>{props.data.pageName}</span>  
            </EpiLink>
        </li>
    );
};

FullMenuItem.propTypes = {
    data: PropTypes.object.isRequired,
    active: PropTypes.bool,
    focusHandler: PropTypes.func,
    language: PropTypes.object
};

FullMenuItem.defaultProps = {
    active: false
};

export default FullMenuItem;
